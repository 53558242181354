import React from "react";
import Title from "../../utils/Title/Title";
import x from "./solar_rocket-2-bold-duotone.png";
import xz from "./solar_ruler-cross-pen-bold-duotone.png";
import xzz from "./solar_star-bold-duotone.png";
const Featuers = () => {
  return (
    <div className=" container">
      <Title title="ما يميز مؤسسة اهداف الرقمية" />
      <div
        className="FeatuersBoxes d-grid gap-5 my-5"
        style={{ gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))" }}
      >
        <div className="box text-center flex justify-center items-center flex-column">
          <img src={x} alt="" />
          <h4>ابتكار تكنولوجي متقدم</h4>
          <p className="">
            نحن نستخدم أحدث التقنيات لتصميم وتطوير حلول تكنولوجية مبتكرة تلبي
            احتياجاتك وتحقق أهدافك بكفاءة عالية. فريقنا من الخبراء يضمن لك
            الحصول على منتجات وخدمات تلبي أعلى معايير الجودة والدقة
          </p>
        </div>
        <div className="box text-center flex justify-center items-center flex-column">
          <img src={xz} alt="" />
          <h4>ابتكار تكنولوجي متقدم</h4>
          <p>
            نحن نستخدم أحدث التقنيات لتصميم وتطوير حلول تكنولوجية مبتكرة تلبي
            احتياجاتك وتحقق أهدافك بكفاءة عالية. فريقنا من الخبراء يضمن لك
            الحصول على منتجات وخدمات تلبي أعلى معايير الجودة والدقة.
          </p>
        </div>
        <div className="box text-center flex justify-center items-center flex-column">
          <img src={xzz} alt="" />
          <h4>ابتكار تكنولوجي متقدم</h4>
          <p>
            نحن نستخدم أحدث التقنيات لتصميم وتطوير حلول تكنولوجية مبتكرة تلبي
            احتياجاتك وتحقق أهدافك بكفاءة عالية. فريقنا من الخبراء يضمن لك
            الحصول على منتجات وخدمات تلبي أعلى معايير الجودة والدقة.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Featuers;
