import React from "react";
import arrow from "./solar_round-arrow-down-bold.png";
import Footer from "../../common/Footer/Footer";
import "./Who_we_are.css";

const Who_we_are = () => {
  return (
    <section>
      <section className="howWeAre container">
        <header className="text-center mt-5">
          <h1 style={{ color: "var(--mainColor)" }} className="mb-3">
            معك من البداية إلى القمة
          </h1>
          <p
            style={{ color: "#6D6D6D", fontSize: "20px" }}
            className="mb-5 w-60 m-auto"
          >
            نحن هنا لنكون شركاءك في رحلتك الرقمية. بدءًا من الفكرة، مرورًا
            بالتنفيذ، وصولًا إلى تحقيق النجاح المنشود. نلتزم بمساعدتك في كل خطوة
            لضمان وصولك إلى القمة.
          </p>
          <button
            style={{
              width: "fit-content",
              border: "1px solid var(--mainColor)",
              color: "var(--mainColor)",
            }}
            className="fw-bold m-auto bg-transparent py-2 px-4 rounded-5 flex items-center gap-4 fs-4"
          >
            <span>أبدا معنا الأن</span>
            <img src={arrow} width={40} alt="" />
          </button>
        </header>
        <div
          className="ved my-5 flex justify-center items-center8"
          style={{ marginBottom: "50px" }}
        >
          <img className="img-who"
            src={require("./Rectangle 99.png")}
            alt=""
            style={{ filter: "brightness(50%)" }}
            loading="lazy"
          />
        </div>
        <div className="moreInfo">
          <p className="fs-5 mt-3 w-70 mb-3 m-auto text-center">
            نحن فريق من الخبراء المتخصصين في مجالات البرمجة، التصميم، والتسويق
            الإلكتروني. نعمل بجد لنقدم لعملائنا أحدث الحلول التقنية التي تلبي
            احتياجاتهم وتساعدهم على تحقيق النجاح في سوق العمل الرقمي المتطور.
          </p>
          <div
            style={{
              gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
            }}
            className="d-grid gap-5 my-4"
          >
            <div
              style={{ border: "1px solid #6D6D6D", borderRadius: "20px" }}
              className="100% p-3"
            >
              <div style={{ fontSize: "30px" }}>👁️</div>
              <h3>رؤيــــــتنا</h3>
              <p>
                نسعى لأن نكون الشريك الموثوق به لعملائنا في عالم الانترنت، من
                خلال تقديم خدمات مبتكرة تتجاوز التوقعات وتساهم في تعزيز تواجدهم
                الرقمي وتمكينهم من التفوق على منافسيهم.
              </p>
            </div>

            <div
              style={{ border: "1px solid #6D6D6D", borderRadius: "20px" }}
              className="100% p-3"
            >
              <div style={{ fontSize: "30px" }}>📧</div>
              <h3>رســــــالتنا</h3>
              <p>
                هدفنا هو تقديم قيمة حقيقية لعملائنا عبر تصميم مواقع إلكترونية
                ذات جودة عالية وسهلة الاستخدام، مع تعزيز تواجدهم الرقمي من خلال
                حملات تسويقية موجهة. نسعى لتحقيق النجاح المشترك بأسعار تنافسية.
              </p>
            </div>

            <div
              style={{ border: "1px solid #6D6D6D", borderRadius: "20px" }}
              className="100% p-3"
            >
              <div style={{ fontSize: "30px" }}>🏆</div>
              <h3>قـــــــيمنا</h3>
              <p>
                نلتزم بأعلى معايير الاحترافية في جميع أعمالنا، لضمان تقديم خدمات
                تتميز بالجودة والدقة. نؤمن بأن الابتكار والإبداع هما مفتاح
                النجاح في عالم التكنولوجيا المتسارع، ونعمل بروح الفريق الواحد
                لتحقيق الأهداف المشتركة بأفضل صورة ممكنة.
              </p>
            </div>
          </div>
        </div>
        <div className="endSection w100 pt-5 z-3"  style={{backgroundColor: "rgba(135, 9, 138, .1)", borderRadius: "30px", marginTop: '200px' }}>
          <div className="content-how pt-5 px-4 w-100 flex justify-between position-relative">
            <div className="content-end-how w-40">
              <h6 style={{color: '#87098A'}} className="mb-3">أولى خطواتك نحو النجاح والتطور</h6>
              <h4 className="content-end-how mb-4">اكتشف حقائق عن اهداف التكنولوجيا</h4>
              <p style={{color:'#6D6D6D'}} className="content-end-how w-70">
                الاستمرار في التطوير وتحسين جودة الخدمات هو الشعار الذي نتبناه
                لتحقيق متطلبات عملائنا والارتقاء بخدماتنا يومًا بعد يوم.
              </p>
            </div>
            <div className="content-end-how w-80">
              <img src={require('./file 1.png')} width={280} className="img-end position-absolute" style={{bottom: '0px'}} alt="" />
              <div className="sections-end w-60 m-auto mb-5" style={{left: '0'}}>
                <div className="secion-end bg-body w-100 mb-3 py-2 flex gap-4 fw-bold" style={{paddingRight: '150px'}}>
                  <img className="img-end-section" src={require('./icons/solar_chat-square-code-bold-duotone.png')} alt="" />
                  <span>برمجة المواقع إلكترونية والتطبيقات</span>
                </div>
                <div className="secion-end bg-body w-100 py-2 flex mb-3 gap-4 fw-bold" style={{paddingRight: '150px'}}>
                  <img className="img-end-section" src={require('./icons/solar_ruler-cross-pen-bold-duotone.png')} alt="" />
                  <span>برمجة المواقع إلكترونية والتطبيقات</span>
                </div>
                <div className="secion-end bg-body w-100 py-2 flex gap-4 fw-bold" style={{paddingRight: '150px'}}>
                  <img className="img-end-section" src={require('./icons/solar_shop-bold-duotone.png')} alt="" />
                  <span>برمجة المواقع إلكترونية والتطبيقات</span>
                </div>
              </div>
            </div>
          </div>
          <footer className="bg-black text-white w-100 flex py-2 px-5 justify-around items-center" style={{borderRadius: "0 0 30px 30px", zIndex: '999999', height: '120px'}}>
            <div className="">
              <h1 className="text-danger">20+</h1>
              <span>سنه من الخبره</span>
            </div>
            <div className="">
              <h1 className="text-danger">20+</h1>
              <span>سنه من الخبره</span>
            </div>
            <div className="">
              <h1 className="text-danger">20+</h1>
              <span>سنه من الخبره</span>
            </div>
          </footer>
        </div>
      </section>
    </section>
  );
};

export default Who_we_are;
