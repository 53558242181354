import React from "react";
import Landing from "../../sections/landing/Landing.section";
import HowWe from "../../sections/HowWE/HowWe";
import Servses from "../../sections/Serveses/Servses";
import LastProjects from "../../sections/LastProjects/LastProjects.section";
import Video from "../../sections/vedioInfo/Video";
import ContactSection from "../../sections/Contact/Contact";
import Testimonials from "../../sections/Testimonials/Testimonials";
import Featuers from "../../sections/Featuers/Featuers";

const Home = () => {
  return (
    <div>
      <Landing />
      <HowWe />
      <Servses />
      <LastProjects />
      <Video />
      {/* <Testimonials /> */}
      <Featuers />
      <ContactSection />
    </div>
  );
};

export default Home;
