import Title from "../../utils/Title/Title";

const servicesData = [
  {
    title: "التسويق الالكترونى",
    description: "يعتبر التسويق الإلكتروني من الأهمية القصوى في عالم الأعمال",
    imgSrc: require("../../sections/Contact/assets/1.png"),
    backgroundColor: "#F9F2FF"
  },
  {
    title: "تصميم مواقع الشركات",
    description: "تصميم مواقع الشركات المتميزة، التي ترتكز على البيانات الحقيقية",
    imgSrc: require("../../sections/Contact/assets/2.png"),
    backgroundColor: "#FEF9ED"
  },
  {
    title: "البرمجة الخاصة",
    description: "نحن في خدمة البرمجة الخاصة نعمل على تأسيس موقع متميز وفريد",
    imgSrc: require("../../sections/Contact/assets/3.png"),
    backgroundColor: "#FEF9ED"
  },
  {
    title: "تصميم الهوية التجارية",
    description: "عباره عن شعار خاص يستخدمه الكثير من المؤسسات الكبرى",
    imgSrc: require("../../sections/Contact/assets/4.png"),
    backgroundColor: "#EFFAFA"
  },
  {
    title: "تصميم المتاجر الالكترونية",
    description: "نحن نوفر خدمة تصميم متجر الكتروني احترافي ومميز",
    imgSrc: require("../../sections/Contact/assets/5.png"),
    backgroundColor: "#FEF9ED"
  },
  {
    title: "تصميم تطبيقات الجوال",
    description: "تصميم تطبيقات الجوال بجودة عالية وبأسعار مناسبة",
    imgSrc: require("../../sections/Contact/assets/6.png"),
    backgroundColor: "#FEF9ED"
  },
  {
    title: "فيديو موشن جرافيك",
    description: "من أفضل الخدمات التي تمكنك من التعبير بطريقة مميزة",
    imgSrc: require("../../sections/Contact/assets/7.png"),
    backgroundColor: "#FEF9ED"
  },
  {
    title: "الدعم الفنى",
    description: "مساعدتك فى الاشياء التقنية و الفنية الذى تربط الاجزاء المادية",
    imgSrc: require("../../sections/Contact/assets/8.png"),
    backgroundColor: "#FEF9ED"
  }
];

const Servses = () => {
  return (
    <div className="container" style={{ marginBlock: "50px" }}>
      <Title
        title={"اكتشف خدماتنا"}
        descreption={
          "نقدم خدمات  ذات جودة عالية ، متوافقة مع المعايير العالمية"
        }
      />
      <div
        className="boxes"
        style={{ marginTop: "50px",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
          gap: "20px"
        }}
      >
        {servicesData.map((service, index) => (
          <div
            key={index}
            className="box w-100 h-100"
            style={{
              border: "1px solid #E6E6E6",
              borderRadius: "20px",
              paddingInline: "20px",
              paddingTop: "20px",
            }}
          >
            <div
              style={{
                marginBottom: "20px",
                height: "40px",
                width: "40px",
                background: service.backgroundColor,
              }}
            >
              <img
                style={{ width: "30px", height: "30px", margin: "5px" }}
                src={service.imgSrc}
                alt="icon"
              />
            </div>
            <h4 className="mb-3"> {service.title} </h4>
            <p style={{ color: "#6D6D6D" }}>
              {service.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Servses;
