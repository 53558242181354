import React from "react";
import Title from "../../utils/Title/Title";
import ContactSection from "../../sections/Contact/Contact";
import map from "./Mask group.png";
import icon from "./solar_streets-map-point-bold-duotone.png";
import style from "./contact.module.css";
import "./contact.module.css"

const mapData = [
  {
    id: 1,
    title: "الممكلة العربية السعودية",
    address: "الرياض, حي الملز, بجوار شركة بيبسي, برج سلمان, الدور التاسع",
    imgSrc: map,
  },
  {
    id: 2,
    title: "الممكلة العربية السعودية",
    address: "الرياض, حي الملز, بجوار شركة بيبسي, برج سلمان, الدور التاسع",
    imgSrc: map,
  },
  {
    id: 3,
    title: "الممكلة العربية السعودية",
    address: "الرياض, حي الملز, بجوار شركة بيبسي, برج سلمان, الدور التاسع",
    imgSrc: map,
  },
];

const Contact = () => {
  const itemStyle = {
    width: "100%",
    padding: "20px",
    borderRadius: "15px",
    backgroundColor: "#f7e8f9",
    textAlign: "center",
    fontFamily: "'Arial', sans-serif",
  };

  const iconStyle = {
    fontSize: "24px",
    color: "#bb31c5",
  };
 
  const titleStyle = {
    color: "#bb31c5",
    marginTop: "10px",
    marginBottom: "10px",
    fontWeight: "bold",
  };

  const infoStyle = {
    fontSize: "18px",
    color: "#333",
  };

  return (
    <div className="">
      <Title
        title={"تواصل معنا"}
        descreption={
          "نسعد بالتواصل معك والإجابة على جميع استفساراتك. فريقنا متواجد على مدار الساعة لتقديم الدعم والمساعدة التي تحتاجها. لا تتردد في الاتصال بنا أو مراسلتنا – نحن هنا لخدمتك"
        }
      />
      <section  className="container  ">
        <div  className="contactBoxes  ">
          <div className="box  ">

              <div className="content-card d-flex gap-3 gap-md-5">
                <div style={{...itemStyle, cursor: "pointer"}} onClick={() => window.open('mailto:ahdafweb79@gmail.com')}>
                  <div style={iconStyle}>📧</div>
                  <div style={titleStyle}>البريد الالكتروني</div>
                  <div style={infoStyle}>ahdafweb79@gmail.com</div>
                </div>
                <div style={{...itemStyle, cursor: "pointer"}} onClick={() => window.open('https://wa.me/201026822710', '_blank')}>
                  <div style={iconStyle}>🛠️</div>
                  <div style={titleStyle}>الدعم الفني</div>
                  <div style={infoStyle}>201026822710+</div>
                </div>
                <div style={{...itemStyle, cursor: "pointer"}} onClick={() => window.open('tel:201026822710')}>
                  <div style={iconStyle}>📞</div>
                  <div style={titleStyle}>أرقام التواصل</div>
                  <div style={infoStyle}>201026822710+
                  </div>
                </div>
              </div>

          </div>
        </div>
        <ContactSection />
      </section>
    </div>
  );
};

export default Contact;
