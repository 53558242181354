import React from "react";
import image from "./bg.png";
import ksa from "./ksa.png";
import "./ProjectCad.css";

const ProjectCad = () => {
  const [projects] = React.useState([
    {
      imgSrc: image,
      title: "حفظني",
      description: "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى",
      link: "/",
      DownloadCound: 332,
      Stores: "جوجل بلاي & أبل ستور",
      location: "السعودية",
      locationLogo: ksa,
    },
  ]);

  return (
    <div className="container projectCrard ">
      {projects.map((project, index) => (
        <div key={index} className={`project  flex  gap-5  items-center rounded-5 mb-5 ${index % 2 === 0 ? "reverse" : ""}`}
          style={{ background: "linear-gradient(to left, #ffffff, #F6F6F6)" }}
        >
          <img src={project.imgSrc} width={300} alt="Background" className="w-full" />
          <div className="content  px-1">
            <h4 className="title mb-3">{project.title}</h4>
            <p className="" style={{ color: "#6D6D6D" }}>
              {project.description}
            </p>
            <div
              className="moreInformation flex gap-5"
              style={{ justifyContent: "flex-start", alignItems: "baseline" }}
            >
              <div className="contentData flex flex-column gap-3 mt-3">
                <div className="countDownload flex flex-column gap-2">
                  <span>التنزيلات</span>
                  <span className="fw-bolder">{project.DownloadCound}K</span>
                </div>
                <div className="stors flex flex-column gap-2">
                  <span>المنصات</span>
                  <span className="fw-bolder"> {project.Stores} </span>
                </div>
              </div>
              <div
                className="technologysRigeon flex flex-column gap-4"
                style={{ marginRight: "40px" }}
              >
                <div className="location flex gap-3 items-center">
                  <img src={project.locationLogo} alt="" />
                  <span>ksa</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProjectCad;
