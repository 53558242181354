import React from "react";
import Title from "../../utils/Title/Title";
import ProjectCad from "../../components/project.Card/ProjectCad";
import arrow from "./solar_arrow-left-outline.png"

const Projects = () => {
  return (
    <div>
      <Title
        title="أعمالنا"
        descreption={
          "نحن فخورون بما أنجزناه مع عملائنا من مشاريع متنوعة ومتميزة. كل مشروع يمثل حكاية نجاح تم بناؤها على أسس من الابتكار والجودة والالتزام بأعلى معايير الاحترافية"
        }
      />
      <div className="projects">
        <ProjectCad />
      </div>
    </div>
  );
};

export default Projects;
