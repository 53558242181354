import React from "react";
import titleStyle from "./title.module.css";
import line from "./assets/line.svg";

const Title = ({ title, descreption }) => {
  return (
    <div className={`${titleStyle.MainTitle}`}>
      <div className={titleStyle.header}>
        <h1 className={titleStyle.title}>{title}</h1>
        <img className={titleStyle.line} src={line} alt="" />
      </div>
      <p className={titleStyle.descreption}>{descreption ? descreption : ""}</p>
    </div>
  );
};

export default Title;
