import React from "react";
import landing from "./landing.module.css";
import avatar from "../../assets/2517863.png";
import startArrow from "../../assets/startArrow.svg";
import landingbtnTheme from "../../assets/landingbtnTheme.svg";
import { Typewriter } from "react-simple-typewriter";
import { useNavigate } from "react-router-dom";
// import Navgation from "../../common/NavgationBar/Navgation";

const Landing = () => {
  const Navigate = useNavigate();
  return (
    <div className={`${landing.landing}`} style={{ height: "100vh" }}>
      <div></div>
      <div className={"container d-flex "}>
        <div className={landing.content}>
          <div style={{ width: "40%" }} className={`${landing.textContent} `}>
            <p className={landing.subtitle}>
              <Typewriter
                words={["مؤسسه اهداف الرقميه"]}
                loop={15}
                cursor
                cursorStyle="_"
                typeSpeed={50}
                deleteSpeed={10}
                delaySpeed={2000}
              />
            </p>
            <h1 className={landing.headTitle}>
              {" نوفر لك "}
              <span className={landing.HeadSpan}>
                {" الحلول التقنية الأفضل "}
              </span>
              {"لتحقيق النجاح الرقمي"}
            </h1>
            <p className={landing.HeadDescription}>
              نحن فريق من الخبراء في البرمجة، التصميم، نقدم لك خدمات مبتكرة
              ومتطورة تلبي احتياجاتك وتحقق أهدافك. اكتشف كيف يمكننا مساعدتك في
              تحقيق التفوق في عالم الإنترنت اليوم!
            </p>
            <button
            onClick={() => Navigate("/contact")}
              className={`px-3 py-1 flex gap-2 align-items-center text-white ${landing.startArrow}`}
            >
              <span>ابدا معنا الان</span>
              <img src={startArrow} alt="Start Arrow" />
            </button>
          </div>
          <div style={{ width: "60%" }} className={`${landing.avatar}`}>
            <img className={landing.image} src={avatar} alt="Avatar" />
          </div>
        </div>
      </div>
      <img
        className=
        {landing.landingbtnTheme}
        src={landingbtnTheme}
        alt="Landing Button Theme"
      />
    </div>
  );
};

export default Landing;
