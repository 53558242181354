import React from "react";
import "./vedio.module.css";

const Video = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "20px",
      }}
      className="container "
    >
      <div className="videoInfo flex column">
        <div style={{ marginBottom: "20px" }}>
          <h5 className="w-70 mb-4">
            فريق عمل من خبراء في مجالات البرمجة والتصميم والتسويق الإلكتروني.
          </h5>
          <p className="text-secondary w-80">
            فريقنا لديه الكفاءة والخبرة اللازمة لتصميم مواقع بأعلى جودة من الدقة
            والمعايير المطلوبة التي تميزها العملاء من خلال استخدام تقنيات النجاح
            في تسويق العلامات التجارية والخدمات بكفاءة واحترافية.
          </p>
          <ul style={{ listStyleType: "none", padding: 0 }}>
            <li className="mb-4 w-70 flex items-center gap-3">
              <svg
                width="64"
                height="64"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M31.1666 16.9999C31.1666 24.8242 24.8242 31.1666 16.9999 31.1666C9.17567 31.1666 2.83325 24.8242 2.83325 16.9999C2.83325 9.17567 9.17567 2.83325 16.9999 2.83325C24.8242 2.83325 31.1666 9.17567 31.1666 16.9999ZM22.7091 12.7074C22.9081 12.9066 23.0198 13.1767 23.0198 13.4583C23.0198 13.7398 22.9081 14.0099 22.7091 14.2091L15.6258 21.2924C15.4265 21.4914 15.1565 21.6032 14.8749 21.6032C14.5934 21.6032 14.3233 21.4914 14.1241 21.2924L11.2908 18.4591C11.1864 18.3618 11.1026 18.2445 11.0446 18.1142C10.9865 17.9838 10.9553 17.8432 10.9527 17.7005C10.9502 17.5578 10.9765 17.4161 11.0299 17.2838C11.0834 17.1515 11.1629 17.0313 11.2638 16.9304C11.3647 16.8296 11.4849 16.75 11.6172 16.6966C11.7495 16.6431 11.8912 16.6169 12.0338 16.6194C12.1765 16.6219 12.3172 16.6532 12.4475 16.7112C12.5778 16.7693 12.6951 16.853 12.7924 16.9574L14.8749 19.0399L18.0412 15.8737L21.2074 12.7074C21.4066 12.5084 21.6767 12.3967 21.9583 12.3967C22.2398 12.3967 22.5099 12.5084 22.7091 12.7074Z"
                  fill="#23D35F"
                />
              </svg>
              هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد
              هذا النص من مولد النص العربى،
            </li>
            <li className="mb-4 w-70 flex items-center gap-3">
              <svg
                width="64"
                height="64"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M31.1666 16.9999C31.1666 24.8242 24.8242 31.1666 16.9999 31.1666C9.17567 31.1666 2.83325 24.8242 2.83325 16.9999C2.83325 9.17567 9.17567 2.83325 16.9999 2.83325C24.8242 2.83325 31.1666 9.17567 31.1666 16.9999ZM22.7091 12.7074C22.9081 12.9066 23.0198 13.1767 23.0198 13.4583C23.0198 13.7398 22.9081 14.0099 22.7091 14.2091L15.6258 21.2924C15.4265 21.4914 15.1565 21.6032 14.8749 21.6032C14.5934 21.6032 14.3233 21.4914 14.1241 21.2924L11.2908 18.4591C11.1864 18.3618 11.1026 18.2445 11.0446 18.1142C10.9865 17.9838 10.9553 17.8432 10.9527 17.7005C10.9502 17.5578 10.9765 17.4161 11.0299 17.2838C11.0834 17.1515 11.1629 17.0313 11.2638 16.9304C11.3647 16.8296 11.4849 16.75 11.6172 16.6966C11.7495 16.6431 11.8912 16.6169 12.0338 16.6194C12.1765 16.6219 12.3172 16.6532 12.4475 16.7112C12.5778 16.7693 12.6951 16.853 12.7924 16.9574L14.8749 19.0399L18.0412 15.8737L21.2074 12.7074C21.4066 12.5084 21.6767 12.3967 21.9583 12.3967C22.2398 12.3967 22.5099 12.5084 22.7091 12.7074Z"
                  fill="#23D35F"
                />
              </svg>
              هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد
              هذا النص من مولد النص العربى،
            </li>
            <li className="mb-4 w-70 flex items-center gap-3">
              <svg
                width="64"
                height="64"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M31.1666 16.9999C31.1666 24.8242 24.8242 31.1666 16.9999 31.1666C9.17567 31.1666 2.83325 24.8242 2.83325 16.9999C2.83325 9.17567 9.17567 2.83325 16.9999 2.83325C24.8242 2.83325 31.1666 9.17567 31.1666 16.9999ZM22.7091 12.7074C22.9081 12.9066 23.0198 13.1767 23.0198 13.4583C23.0198 13.7398 22.9081 14.0099 22.7091 14.2091L15.6258 21.2924C15.4265 21.4914 15.1565 21.6032 14.8749 21.6032C14.5934 21.6032 14.3233 21.4914 14.1241 21.2924L11.2908 18.4591C11.1864 18.3618 11.1026 18.2445 11.0446 18.1142C10.9865 17.9838 10.9553 17.8432 10.9527 17.7005C10.9502 17.5578 10.9765 17.4161 11.0299 17.2838C11.0834 17.1515 11.1629 17.0313 11.2638 16.9304C11.3647 16.8296 11.4849 16.75 11.6172 16.6966C11.7495 16.6431 11.8912 16.6169 12.0338 16.6194C12.1765 16.6219 12.3172 16.6532 12.4475 16.7112C12.5778 16.7693 12.6951 16.853 12.7924 16.9574L14.8749 19.0399L18.0412 15.8737L21.2074 12.7074C21.4066 12.5084 21.6767 12.3967 21.9583 12.3967C22.2398 12.3967 22.5099 12.5084 22.7091 12.7074Z"
                  fill="#23D35F"
                />
              </svg>
              هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد
              هذا النص من مولد النص العربى،
            </li>
            <li className="mb-4 w-70 flex items-center gap-3">
              <svg
                width="64"
                height="64"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M31.1666 16.9999C31.1666 24.8242 24.8242 31.1666 16.9999 31.1666C9.17567 31.1666 2.83325 24.8242 2.83325 16.9999C2.83325 9.17567 9.17567 2.83325 16.9999 2.83325C24.8242 2.83325 31.1666 9.17567 31.1666 16.9999ZM22.7091 12.7074C22.9081 12.9066 23.0198 13.1767 23.0198 13.4583C23.0198 13.7398 22.9081 14.0099 22.7091 14.2091L15.6258 21.2924C15.4265 21.4914 15.1565 21.6032 14.8749 21.6032C14.5934 21.6032 14.3233 21.4914 14.1241 21.2924L11.2908 18.4591C11.1864 18.3618 11.1026 18.2445 11.0446 18.1142C10.9865 17.9838 10.9553 17.8432 10.9527 17.7005C10.9502 17.5578 10.9765 17.4161 11.0299 17.2838C11.0834 17.1515 11.1629 17.0313 11.2638 16.9304C11.3647 16.8296 11.4849 16.75 11.6172 16.6966C11.7495 16.6431 11.8912 16.6169 12.0338 16.6194C12.1765 16.6219 12.3172 16.6532 12.4475 16.7112C12.5778 16.7693 12.6951 16.853 12.7924 16.9574L14.8749 19.0399L18.0412 15.8737L21.2074 12.7074C21.4066 12.5084 21.6767 12.3967 21.9583 12.3967C22.2398 12.3967 22.5099 12.5084 22.7091 12.7074Z"
                  fill="#23D35F"
                />
              </svg>
              هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد
              هذا النص من مولد النص العربى،
            </li>
          </ul>
        </div>
        <div
          style={{
            position: "relative",
            width: "100%",
            paddingBottom: "56.25%",
            backgroundColor: "#ccc",
            borderRadius: "10px",
            marginTop: "20px",
          }}
        >
          <button
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              background: "none",
              border: "none",
              cursor: "pointer",
              height: "200px",
            }}
          >
            <svg
              width="64"
              height="64"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polygon points="5 3 19 12 5 21 5 3"></polygon>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Video;
