import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./nav.module.css";
import "../NavgationBar/nav.module.css";
import { NavDropdown } from "react-bootstrap";

const Navgation = () => {
  const [active, setActive] = useState(false);
  const handleClick = () => {
    setActive(!active);
  };

  return (
    <div
      className={`${styles.navBarHome} d-flex justify-content-around  align-items-center p-5`}
    >
      <div className="logo">
        <img
          className="logo-img"
          src={require("../../assets/logo_text.png")}
          alt=""
          width={120}
        />
      </div>
      <div className={`${styles.links} ${active ? styles.active : ""} fs-5`}>
        <div>
          <Link
            className={`${styles.link} text-white text-decoration-none`}
            to="/"
          >
            {"الرئيسية"}
          </Link>
        </div>
        <div>
          <Link
            className={`${styles.link} text-white text-decoration-none`}
            to="/who_we_are"
          >
            {"من نحن"}
          </Link>
        </div>
        <div>
          {" "}
          <div className={`${styles.link}  text-white text-decoration-none`}>
            <NavDropdown
            
              className={`${styles.menu}`}
              title="خدماتنا"
              id="collapsible-nav-dropdown"
              style={{ textAlign: "right", direction: "rtl" }}
            >
              <NavDropdown.Item
                className={`${styles.dropList}`}
                style={{
                  color: "black",
                  fontSize: "15px",
                  fontWeight: "bold",
                  textAlign: "right",
                  right: "0",
                  direction: "rtl",
                }}
              >
                {"تصميم مواقع الشركات"}
              </NavDropdown.Item>
              <NavDropdown.Item
                className={`${styles.dropList}`}
                style={{
                  color: "black",
                  fontSize: "15px",
                  fontWeight: "bold",
                  textAlign: "right",
                  right: "0",
                  direction: "rtl",
                }}
              >
                {"التسويق الالكتروني"}
              </NavDropdown.Item>
              <NavDropdown.Item
                className={`${styles.dropList}`}
                style={{
                  color: "black",
                  fontSize: "15px",
                  fontWeight: "bold",
                  textAlign: "right",
                  right: "0",
                  direction: "rtl",
                }}
              >
                {"البرمجه الخاصه"}
              </NavDropdown.Item>
              <NavDropdown.Item
                className={`${styles.dropList}`}
                style={{
                  color: "black",
                  fontSize: "15px",
                  fontWeight: "bold",
                  textAlign: "right",
                  right: "0",
                  direction: "rtl",
                }}
              >
                {"فيديو موشن جرافيك"}
              </NavDropdown.Item>
              <NavDropdown.Item
                className={`${styles.dropList}`}
                style={{
                  color: "black",
                  fontSize: "15px",
                  fontWeight: "bold",
                  textAlign: "right",
                  right: "0",
                  direction: "rtl",
                }}
              >
                {"تصميم الهويه التجاريه"}
              </NavDropdown.Item>
              <NavDropdown.Item
                className={`${styles.dropList}`}
                style={{
                  color: "black",
                  fontSize: "15px",
                  fontWeight: "bold",
                  textAlign: "right",
                  right: "0",
                  direction: "rtl",
                }}
              >
                {"تصميم المتاجر الالكترونيه"}
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </div>
        <div>
          <Link
            className={`${styles.link} text-white text-decoration-none`}
            to="/projects"
          >
            {"اعمالنا"}
          </Link>
        </div>
        <div>
          <Link
            className={`${styles.link} text-white text-decoration-none `}
            to="/contact"
          >
            {"تواصل معنا"}
          </Link>
        </div>
        <button onClick={handleClick} className={`${styles.btnClose}`}>
          {" "}
          X
        </button>
      </div>
      <div
        className={styles.bars}
        style={{ cursor: "pointer", color: "white" }}
        onClick={handleClick}
      >
        <img
          src={require("./burger-bar.png")}
          width={30}
          alt="bars"
          style={{ zIndex: "9999" }}
        />
      </div>
    </div>
  );
};

export default Navgation;
