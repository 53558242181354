import { Route, Routes } from "react-router-dom";
import "./ashrafmo-1classes.css";
import Home from "./pages/Home/Home";
import Who_we_are from "./pages/HowWeAre/Who_we_are";
import Contact from "./pages/Contact/Contact";
import Projects from "./pages/projects/Projects";
import ProjectInfornation from "./pages/projectInformation/ProjectInfornation";
import ServsesInfo from "./pages/servesesInfo/ServsesInfo";
import Footer from "./common/Footer/Footer";
import { useEffect } from "react";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { firebaseApp } from './firebase'; // Import your Firebase config
import Navgation from "./common/NavgationBar/Navgation";

function App() {
  useEffect(() => {
    const messaging = getMessaging(firebaseApp);

    // Request permission for notifications
    const requestNotificationPermission = async () => {
      try {
        await Notification.requestPermission();
        console.log("Notification permission granted.");

        // Get FCM Token
        const token = await getToken(messaging, {
          vapidKey: "YOUR_PUBLIC_VAPID_KEY" // Replace this with your VAPID key if you have one
        });
        console.log("FCM Token:", token);
        // You can now send the token to your server for later use
      } catch (error) {
        console.log("Unable to get permission to notify.", error);
      }
    };

    requestNotificationPermission();

    // Listen for incoming messages
    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);

      // Customize notification here
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
        icon: payload.notification.icon,
      };

      if (Notification.permission === "granted") {
        new Notification(notificationTitle, notificationOptions);
      }
    });
  }, []);
  return (
    <main dir="rtl">
      <Navgation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="who_we_are" element={<Who_we_are />} />
        <Route path="contact" element={<Contact />} />
        <Route path="projects" element={<Projects />} />
        <Route path="projects/moreInfo" element={<ProjectInfornation />} />
        <Route path="Serv/more-info" element={<ServsesInfo />} />
      </Routes>
      <Footer />
    </main>
  );
}

export default App;