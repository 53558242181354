import { ListGroup } from "react-bootstrap";
import Title from "../../utils/Title/Title";
import styles from "./styles.module.css";
import phone from "./assets/solar_phone-calling-bold-duotone.png";
import call_center from "./assets/solar_chat-square-call-bold-duotone.png";
import map from "./assets/solar_streets-map-point-bold-duotone.png";
import arrow from "./assets/solar_round-arrow-down-bold.png";
import { useState } from "react";
import Select from "react-select";

const options = [
  { value: "chocolate", label: "التسويق الالكتروني" },
  { value: "strawberry", label: "تصميم مواقع الشركات" },
  { value: "vanilla", label: "البرمجة الخاصة" },
  { value: "vanilla", label: "تصميم الهوية التجارية" },
  { value: "vanilla", label: "تصميم المتاجر الالكترونية" },
  { value: "vanilla", label: "تصميم تطبيقات الجوال" },
  { value: "vanilla", label: "فيديو موشن جرافيك" },
  { value: "vanilla", label: "الدعم الفني" },
];

const ContactSection = () => {
  const [selectedOption, setSelectedOption] = useState(null);

  return (
    <div
      className={styles.contactUs + " container  w-100  "}
      style={{ background: "transparent" }}
    >
      <Title
        title={"أبقى على اتصال معنا"}
        descreption={
          "نحن هنا لدعمك! اتصل بنا اليوم لأي استفسارات أو طلبات، وسنكون سعداء بمساعدتك في تحقيق أهدافك التكنولوجية."
        }
      />
      <section
        className={
          styles.Contact + "  w-100 flex mt-5 gap-5 space justify-between"
        }
      >
        <div
          className="staticData p-4 w-100 gap-2"
          style={{
            background: "#1E1E1E",
            borderRadius: "30px",
            width: "400px ",
          }}
        >
          <h3 className="  mb-4 fs-3 text-white pt-4">كن على تواصل</h3>
          <p
            className="text-white pb-2 w-90"
            style={{ borderBottom: "1px solid #000" }}
          >
            اتصل بنا عبر أرقام الهواتف التالية للحصول على الدعم الفوري
            والاستفسارات العامة.
          </p>
          <div>
            <ListGroup.Item className="border-0 text-white d-flex gap-4 align-items-center mb-4">
              <img
                style={{ background: "#303030", borderRadius: "50px" }}
                className="p-3"
                src={phone}
                alt=""
              />
              <div className="flex flex-column gap-3">
                <span style={{ color: "var(--YColor)" }}>أرقام التواصل</span>
                <span>201026822710+</span>
              </div>
            </ListGroup.Item>
          </div>
          {/* <h5 className="mt-4 text-white">مواقع مكاتبنا</h5> */}
          {/* <div className="m-5">
            <ListGroup.Item className="border-0 text-white d-flex align-items-center ">
              <span>المملكة العربية السعودية:</span>
              الرياض، حي المراز، بجوار شركة مصيبيح، برج سلمان، الدور السابع
            </ListGroup.Item>
            <ListGroup.Item className="border-0 text-white d-flex align-items-center mt-5">
              المملكة العربية السعودية: جدة، حي الصفاء، مقابل المولات، مبنى رقم
              7، الدور الثالث
            </ListGroup.Item>
            <ListGroup.Item className="border-0 text-white d-flex align-items-center">
              الإمارات العربية: شارع المرور بجوار فندق سي فاطمة، الدور الخامس
            </ListGroup.Item>
            <ListGroup.Item className="border-0 text-white d-flex align-items-center">
              الكويت: شارع حسين بن علي الرومي، برج بنيان، مقابل محكمة الرقعي،
              الدور الأول والثاني
            </ListGroup.Item>
          </div> */}
        </div>
        {/* start form */}
        <div
          className=" contact-2 w-100 border p-4"
          style={{ borderRadius: "30px" }}
        >
          <h2 className="mt-4 w-50">ارسل لنا رسالة</h2>
          <p style={{ color: "#6D6D6D" }}>
            يمكنك ملء البيانات التالية وارسل لنا استفسارك وسنتواصل معك فى اقرب
            وقت
          </p>
          <form className=" flex flex-column">
            <div className="name flex gap-4 mb-4">
              <input
                type="text"
                className="w-100 rounded-4 px-3"
                style={{ height: "50px", border: "1px solid #e1e1e1" }}
                name=""
                id=""
                placeholder="الاسم الاول"
              />
              <input
                type="text"
                className="w-100 rounded-4 px-3"
                style={{ height: "50px", border: "1px solid #e1e1e1" }}
                name=""
                id=""
                placeholder="الاسم الاخير"
              />
            </div>
            <div className="contact flex gap-4 mb-4">
              <input
                type="text"
                name=""
                className="w-100 rounded-4 px-3"
                style={{ height: "50px", border: "1px solid #e1e1e1" }}
                id=""
                placeholder="رقم الهاتف"
              />
              <input
                type="text"
                name=""
                className="w-100 rounded-4 px-3"
                style={{ height: "50px", border: "1px solid #e1e1e1" }}
                id=""
                placeholder="البريد الالكتروني"
              />
            </div>
            <Select
              value={selectedOption}
              onChange={setSelectedOption}
              options={options}
              placeholder="اختار الخدمه"
            />
            <textarea
              name=""
              style={{
                resize: "none",
                height: "127px",
                border: "1px solid #e1e1e1",
              }}
              className="mt-3 my-3 rounded-4 px-3"
              id=""
            ></textarea>
            <button
              style={{
                width: "fit-content",
                border: "1px solid var(--mainColor)",
                color: "var(--mainColor)",
              }}
              className="mt-3 fw-bold m-auto bg-transparent py-2 px-4 rounded-5 flex items-center gap-4 fs-4"
            >
              <span>أبدا معنا الأن</span>
              <img src={arrow} width={40} alt="" />
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default ContactSection;
