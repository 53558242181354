import styles from "./footer.module.css";
import "./footer.module.css"
import topLine from "./assets/topLine.svg";
import { Link, useLoaderData } from "react-router-dom";
import arrow from "./assets/arrow.svg";
const Footer = () => {
  const sitePages = [
    { title: "الرئيسيه", path: "/" },
    { title: "اعمالنا", path: "/" },
    { title: "من نحن", path: "/" },
    { title: "خدماتنا", path: "/" },
    { title: "المدونه", path: "/" },
    { title: "اتصل بنا", path: "/" },
  ];

  const mainServices = [
    { title: "تصميم مواقع الشركات", path: "/" },
    { title: "التسويق الالكتروني", path: "/" },
    { title: "البرمجه الخاصه", path: "/" },
    { title: "فيديو موشن جرافيك", path: "/" },
    { title: "تصميم الهويه التجاريه", path: "/" },
    { title: "تصميم المتاجر الالكترونيه", path: "/" },
  ];

  return (
    <div className={`${styles.Footer} container-footer`}>
      <div>
        <header className={styles.footerHead}>
          <div className="intro">
            <img className="logo-footer"
              src={require("../../assets/logo.png")}
              style={{width:"120px"}}
              alt=""
            />
            <p style={{ width: "300px" }} className="intro-des mt-3  text-white">
              نحن شركة رائدة في تقديم حلول تكنولوجية مبتكرة تشمل تصميم وتطوير
              المواقع الإلكترونية، التسويق الإلكتروني، وتحليل البيانات. نلتزم
              بتقديم خدمات عالية الجودة تلبي احتياجات عملائنا وتساعدهم على تحقيق
              أهدافهم في عالم التكنولوجيا الرقمي.
            </p>
          </div>
          <div className={useLoaderData.pagesLinks}>
            <h5 className="mb-3" style={{ color: "var(--YColor)" }}>
              صفحات الموقع
            </h5>
            <div className="links flex flex-column">
              {sitePages.map((page, index) => (
                <Link
                  key={index}
                  className="text-white text-decoration-none fw-bold gap-2 flex"
                  to={page.path}
                >
                  {<img src={arrow} width={30} alt="" />}
                  <span>{page.title}</span>
                </Link>
              ))}
            </div>
          </div>
          <div className={useLoaderData.pagesLinks}>
            <h5 className="mb-4" style={{ color: "var(--YColor)" }}>
              اهم خدماتنا
            </h5>
            <div className="links flex flex-column">
              {mainServices.map((service, index) => (
                <Link
                  key={index}
                  className="text-white text-decoration-none fw-bold gap-2 flex"
                  to={service.path}
                >
                  {<img src={arrow} width={30} alt="" />}
                  {service.title}
                </Link>
              ))}
            </div>
          </div>
          <div className="subScipe">
            <h5 className="mb-3" style={{ color: "var(--YColor)" }}>
              اشترك معنا لان
            </h5>
            <p
              className="description text-white fw-bold"
              style={{ width: "300px" }}
            >
              اشترك معنا فى النشرة الاخبارية ليصلك جديد اخبارنا وعروضنا باستمرار
            </p>
            <form>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="البريد الالكتروني"
                className="py-1 px-3 rounded-2"
                style={{ width: "310px", height: "50px", border: "none" }}
              />
            </form>
            <p style={{ color: "var(--YColor)" }} className="mt-3 fw-bold">
              يسعدنا متابعتكم لنا
            </p>
          </div>
        </header>
        <div
          className={`${styles.bottomCopyRight} container flex items-center justify-between mt-2`}
          style={{ borderTop: "1px solid #7C077F" }}
        >
          <p className={`py-2 ${styles.copyRight}`}>
            © {new Date().getFullYear()} جميع الحقوق محفوظة لمؤسسة اهداف 
            لتكنولوجيا المعلومات
          </p>
          <p className={`py-2 ${styles.copyRight}`}>
            سياسة الخصوصية - الشروط والاحكام
          </p>
        </div>
        <img className={styles.topLine} src={topLine} alt="" />
      </div>
    </div>
  );
};

export default Footer;
