import React, { useState } from "react";
import Title from "../../utils/Title/Title";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import image from "./bg.png";
import ksa from "./ksa.png";
import styles from "./lastProjects.module.css";

// skills
import x from "./skills/FaUOqdRy6QJZSC4wFFecoP3ZC2remPgxepHhgFLF 1.png";
import x1 from "./skills/LrkyeyZcp5R58Pi6kiWKAazDpKDOiEhJhyP7iztt 1.png";
import x2 from "./skills/MVNsufWz2iZ2MXm2gv70MK1XhINvvcGki7uSLFnL 1.png";
import x3 from "./skills/SW8GOW4GvQgrKsFuY9NZA5VUlPgaAu5sMQC6YwfZ 1.png";
import x4 from "./skills/UexVl1X8p76Iyn3cqhLgjFYQgLgjORPhnWvIkY5l 1.png";
import x5 from "./skills/iQ6PD2LdyohMx06s9merDRWUz0hUHLkj7penm93A 1.png";
import x6 from "./skills/pza45QswCCx21aDeau79gTYle8mQFSI6M8HTv8I1 1.png";

const LastProjects = () => {
  const [swiperInstance, setSwiperInstance] = useState(null);

  const handleSlideChange = (swiper) => {
    console.log("Slide changed to: ", swiper.activeIndex);
  };

  const projects = [
    {
      imgSrc: image,
      title: "تطبيق حفظني",
      description:
        "يقدم حفظني مجموعة واسعة من الدورات لجميع المستويات، من التعليم الابتدائي إلى التعليم الجامعي. يمكنك الوصول إلى الدورات في مجالات مختلفة، بما في ذلك العلوم والأدب والتاريخ وغيرها. الدورات مُنظمة بشكل جيد وسهلة الفهم، مع اختبارات تفاعلية وتقييمات لاختبار معرفتك. يسمح التطبيق أيضًا لك بتتبع تقدمك والاحتفاظ بسجل دوراتك المنجزة.",
      link: "/",
      DownloadCound: 332,
      Stores: "جوجل بلاي & أبل ستور",
      location: "السعودية",
      locationLogo: ksa,
    },
    {
      imgSrc: image,
      title: "اسم التطبيق من الاعمال",
      description:
        "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى",
      link: "/",
      DownloadCound: 332,
      Stores: "جوجل بلاي & أبل ستور",
      location: "السعودية",
      locationLogo: ksa,
      skills: [x, x1, x2, x3, x4, x5, x6],
    },
  ];

  return (
    <div className="last-projects-container  mb-5 w-90 m-auto pb-5">
      <Title title={"اخر اعمالنا"} />
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        onSlideChange={handleSlideChange}
        onSwiper={setSwiperInstance}
        pagination={{
          clickable: true,
          el: ".swiper-pagination",
          type: "bullets",
        }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        modules={[Pagination, Navigation]}
        className="mySwiper h-100 mt-5"
      >
        {projects.map((project, index) => (
          <SwiperSlide
            key={index}
            className={`${styles.box_project} d-flex items-center rounded-5`}
            style={{ background: "linear-gradient(to left, #ffffff, #F6F6F6)" }}
          >
            <img
              src={project.imgSrc}
              width={500}
              alt="Background"
              className={`${styles.projectImage} w-full`}
            />
            <div className={`${styles.content} w-100 py-3 h-100 px-1`}>
              <h4 className={`${styles.title} mb-3`}>{project.title}</h4>
              <p
                className={`${styles.description} mb-3`}
                style={{ color: "#6D6D6D" }}
              >
                {project.description}
              </p>
              <div
                className={`${styles.moreInformation} flex gap-5`}
                style={{ justifyContent: "flex-start", alignItems: "baseline" }}
              >
                <div className="contentData flex flex-column gap-3 mt-3">
                  <div className="countDownload flex flex-column gap-2">
                    <span>التنزيلات</span>
                    <span className="fw-bolder">{project.DownloadCound}K</span>
                  </div>
                  <div className="stors flex flex-column gap-2">
                    <span>المنصات</span>
                    <span className={`${styles.storesName} fw-bolder`}>
                      {" "}
                      {project.Stores}{" "}
                    </span>
                  </div>
                </div>
                <div
                  className={`${styles.technologysRigeon} flex flex-column gap-4`}
                  style={{ marginRight: "40px" }}
                >
                  <div className="location flex gap-3 items-center">
                    <img
                      src={project.locationLogo}
                      className={styles.cotry}
                      alt=""
                    />
                    <span>ksa</span>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
        <div className="swiper-button-next"></div>
        <div className="swiper-button-prev"></div>
        <div className="swiper-pagination"></div>
      </Swiper>
      <Link
        className="flex justify-center mt-5 m-auto border px-4 py-2 rounded-5"
        style={{ width: "fit-content" }}
        to={""}
      >
        عرض المزيد
      </Link>
    </div>
  );
};

export default LastProjects;
