import HowWeStyle from "./How.module.css";

import { motion } from "framer-motion";
import x1 from "./solar_clock-circle-bold-duotone.png"
import x2 from "./solar_dollar-minimalistic-bold-duotone.png"
import x3 from "./solar_medal-ribbons-star-bold-duotone.png"


const HowWe = () => {

  return (
    <> 
    <div className={`container w-100 ${HowWeStyle.content}`}>
      <p className={HowWeStyle.subTitle}>{"من نحن"}</p>
      <div className={`flex justify-between gap-5 ${HowWeStyle.headInfo}`}>
        <h4 className={HowWeStyle.headTitle}>
          {
            "فريق عمل من خبراء في مجالات البرمجة والتصميم والتسويق الإلكتروني. "
          }
        </h4>
        <p className={HowWeStyle.description}>فريقنا لديه الكفاءة والخبرة اللازمة لتصميم مواقع بأعلى جودة من الدقة
          والمعايير المطلوبة التي يحتاجها العميل وسهلة الاستخدام وتحقيق النجاح
          في تسويق المنتجات والخدمات الخاصة بعملائنا.
        </p>
      </div>
      <div className={`${HowWeStyle.boxes} gap-5`} style={{marginTop: '50px'}}>
            <motion.div className={`box w-100 p-4 ${HowWeStyle.box}`} style={{border: '1px solid #555', borderRadius: '30px'}} initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
            <img src={x3} alt="" className="logo" style={{backgroundColor: "#8ddcf894", padding: '10px', borderRadius: '10px', marginBottom: '30px'}} />
            <h3 className="title"> {"جودة عالية"} </h3>
            <p className="description" style={{color: '#6D6D6D'}}> {"نحرص على تقديم خدمات بأعلى مستويات الجودة والدقة ."} </p>
          </motion.div>
            <motion.div className={`box w-100 p-4 ${HowWeStyle.box}`} style={{border: '1px solid #555', borderRadius: '30px'}} initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
            <img src={x2} alt="" className="logo" style={{backgroundColor: "#8ddcf894", padding: '10px', borderRadius: '10px', marginBottom: '30px'}} />
            <h3 className="title"> {"أسعار منافسة"} </h3>
            <p className="description" style={{color: '#6D6D6D'}}> {"نوفر خدمات مميزة بأسعار مناسبة تضمن لك التفوق في السوق."} </p>
          </motion.div>
            <motion.div className={`box w-100 p-4 ${HowWeStyle.box}`} style={{border: '1px solid #555', borderRadius: '30px'}} initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
            <img src={x1} alt="" className="logo" style={{backgroundColor: "#8ddcf894", padding: '10px', borderRadius: '10px', marginBottom: '30px'}} />
            <h3 className="title"> {"دعم على مدار الساعة"} </h3>
            <p className="description" style={{color: '#6D6D6D'}}> {"فريق خدمة العملاء متواجد على مدار اليوم للرد على استفساراتك وتلبية طلباتك بشكل سريع وفعال."} </p>
          </motion.div>
      </div>
    </div>
    </>
  );
};

export default HowWe;
