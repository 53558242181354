// Import the necessary Firebase services
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const config = {
    apiKey: "AIzaSyBJJndWQM15vIzuH6X4k3idS9Otf2HZCPo",
    authDomain: "rmoz-notfcation.firebaseapp.com",
    projectId: "rmoz-notfcation",
    storageBucket: "rmoz-notfcation.appspot.com",
    messagingSenderId: "694492189341",
    appId: "1:694492189341:web:e0526787793d2e6ce0fa74",
    measurementId: "G-VGF4MDB252"
};

// Initialize Firebase
const firebaseApp = initializeApp(config);

// Optionally initialize other services (e.g., auth and firestore)
const auth = getAuth(firebaseApp);
const firestore = getFirestore(firebaseApp);

export { firebaseApp, auth, firestore };