import React from "react";
import hello from "./he.svg";
import Title from "../../utils/Title/Title";
import Footer from "../../common/Footer/Footer";

const ServsesInfo = () => {
  return (
    <div className="mainServSevction">
      <section className="container my-5 pt-5">
        <div className="headerInfo flex justify-between gap-5">
          <div className="content">
            <h3 className="title"> {"خدمه التسويق الالكتروني"} </h3>
            <p style={{ fontSize: "22px" }} className="mt-4 text-secondary">
              هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد
              هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو
              العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها
              التطبيق.
            </p>
            <button
              className="py-2 rounded-5 fs-5 px-4 text-white flex items-center justify-center"
              style={{ border: "none", backgroundColor: "var(--mainColor)" }}
            >
              {"اطلب خدماتنا الان"}
            </button>
          </div>
          <img src={hello} alt="sf" className="headerImage" />
        </div>
        <Title
          title={"ما يميزنا بتقديم خدمة التسويق الالكتروني"}
          descreption={
            "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق."
          }
        />
        <div className="boxes flex mt-5 justify-between">
            <div className="box">
                <img src={hello} width={400} alt="" />
            </div>
            <div className="box">
                <img src={hello} width={400} alt="" />
            </div>
            <div className="box">
                <img src={hello} width={400} alt="" />
            </div>
        </div>


        
        {/*  */}
        <div
        className="makeStarting container text-white rounded-5 p-5 text-center my-5"
        style={{
          backgroundColor: "var(--mainColor)",
        //   backgroundImage: backGround,/
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="">
          <h1>أنشئ حضورك الرقمي بكل سهولة</h1>
          <p className="w-60 m-auto mt-3 mb-5 text-white">
            نوفر لك الأدوات والخبرات اللازمة لإطلاق موقعك الإلكتروني باحترافية
            وبدون تعقيدات. من التصميم إلى التنفيذ، نحن هنا لنساعدك على بناء وجود
            رقمي قوي وجذاب يعكس هوية علامتك التجارية ويساهم في نجاحك.
          </p>
          <button className=" bg-transparent border py-2 px-4 rounded-5 fs-4 fw-bold text-white">
            أبدا معنا الأن
          </button>
        </div>
      </div>
      </section>
    </div>
  );
};

export default ServsesInfo;
