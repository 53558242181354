import React from "react";
import Footer from "../../common/Footer/Footer";
import hello from "./x.svg";
import ph from "./phones.svg";
import backGround from "./Mask group bggggggggggggggg.png";

const ProjectInfornation = () => {
  return (
    <div>
      <section className="container my-5 pt-5">
        <div className="headerInfo flex justify-between gap-5">
          <div className="content">
            <h3 className="title">اسم التطبيق </h3>
            <p>
              هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد
              هذا النص من مولد النص العربى،
            </p>
          </div>
          <img src={hello} alt="sf" className="headerImage" />
        </div>
      </section>
      <div
        className="moreInfo bg-body-secondary pb-4"
        style={{ marginTop: "-200px" }}
      >
        <div className="container flex align-items-end gap-5">
          <img src={ph} alt="" style={{ transform: "scale(1.1)" }} />
          <div className="content" style={{ paddingBottom: "100px" }}>
            <h3 className="title">اسم التطبيق </h3>
            <p>
              هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد
              هذا النص من مولد النص العربى،
            </p>
          </div>
        </div>
      </div>
      <div className="mainThengs my-5 bg-body-secondary">
        <div className="container">
          <h3>اسم التطبيق</h3>
          <div className="infoBoxes flex justify-between items-center mt-5 py-4">
            <div className="">
              <div className="data">2022</div>
              <h4 className="title">{"وقت الانطلاق"}</h4>
            </div>
            <div className="">
              <div className="data">2022</div>
              <h4 className="title">{"االموقع"}</h4>
            </div>
            <div className="">
              <div className="data">545K</div>
              <h4 className="title">{"عدد التحميلات"}</h4>
            </div>
            <div className="">
              <div className="data">5</div>
              <h4 className="title">{"التقييم"}</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="makeStarting container text-white rounded-5 p-5 text-center mb-md-5"
        style={{
          backgroundColor: "var(--mainColor)",
          backgroundImage: backGround,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="">
          <h1>أنشئ حضورك الرقمي بكل سهولة</h1>
          <p className="w-60 m-auto mt-3 mb-5 text-white">
            نوفر لك الأدوات والخبرات اللازمة لإطلاق موقعك الإلكتروني باحترافية
            وبدون تعقيدات. من التصميم إلى التنفيذ، نحن هنا لنساعدك على بناء وجود
            رقمي قوي وجذاب يعكس هوية علامتك التجارية ويساهم في نجاحك.
          </p>
          <button className=" bg-transparent border py-2 px-4 rounded-5 fs-4 fw-bold text-white">
            أبدا معنا الأن
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProjectInfornation;
